<template>

    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h2 class=" mb-1">Project Milestone</h2>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="border-bottom mb-3">
                                    <div class="table-responsive remove-auto-overflow">
                                        <table class="table table-hover table-striped">
                                            <thead class="table-header">
                                            <tr>
                                                <th scope="col">Milestone </th>
                                                <th scope="col">Date</th>
                                                <th scope="col" v-if="projectStatus != 'Completed'">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody v-if="data.dataList.length > 0">
                                                <tr v-for="(row, rowIndex) in data.dataList">
                                                    <td>
                                                        <div class="w-50">
                                                            <select class="form-select" aria-label="User-Milestone" :disabled="row.is_edit" v-model="row.milestone_id">
                                                                <option :value="''">Milestone</option>
                                                                <option :value="list.id" v-for="(list, key) in data.dropdownData" :key="key"
                                                                 :disabled="checkIsItemDisabled(list)">
                                                                    {{ list.name }} </option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div v-if="row.type == 'date'">
                                                            <input type="date" class="form-control" v-model="row.date" :disabled="row.is_edit">
                                                            <span v-if="row.probable_date != null" @click="makeItTextField(row)"><a href="#">Make it text field</a></span>
                                                        </div>
                                                        <div v-else>
                                                            <Multiselect
                                                                v-model="row.probable_date"
                                                                placeholder="Select Probable Date"
                                                                :options="milestoneFixedText.text"
                                                                :searchable="true"
                                                                :disabled="row.is_edit">
                                                            </Multiselect>
                                                            <span @click="makeItDateField(row)"><a href="#">Make it date field</a></span>
                                                        </div>
                                                    </td>
                                                    <td v-if="projectStatus != 'Completed'">
                                                        <button class="me-2 btn btn-primary btn-sm" @click="editRow(rowIndex)" v-if="row.is_edit && data.permission.canEdit"
                                                            :disabled="invitationData.invitation_status === 'Invited' ? true : false">
                                                            <font-awesome-icon :icon="['fas', 'edit']"></font-awesome-icon>
                                                        </button>
                                                        <button v-if="data.permission.canCreate" class="me-2 btn btn-green btn-sm" @click="saveData(rowIndex)"
                                                            :disabled="invitationData.invitation_status === 'Invited' ? true : false">
                                                            <font-awesome-icon :icon="['fas', 'save']"></font-awesome-icon>
                                                        </button>
                                                        <button class="me-2 btn btn-danger btn-sm" @click="deleteRow(rowIndex, row)" v-if="row.is_edit && data.permission.canDelete"
                                                            :disabled="invitationData.invitation_status === 'Invited' ? true : false">
                                                            <font-awesome-icon :icon="['fas', 'trash-alt']"></font-awesome-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-if="data.dataList.length < 1">
                                        <p class="text-mute text-center sm-text">No Data Available</p>
                                    </div>
                                </div>
                                <div v-if="data.permission.canCreate && projectStatus != 'Completed'">
                                    <button @click="addMore" class="btn btn-primary" type="button"
                                        :disabled="invitationData.invitation_status === 'Invited' ? true : false"
                                    >+ More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import HelperFunction from "@/common/helpers";
    import {computed, inject, onMounted, reactive} from "vue";
    import {useStore} from "vuex";
    import {useRoute} from "vue-router";
    import Multiselect from '@vueform/multiselect';

    export default {
        name: "ProjectMilestone",
        components: {
            Multiselect
        },
        setup() {
            const {data, openModal, deleteListData, getAllData, storeFormData, permissionResolver, getFormatedMileStone} = HelperFunction();
            const store = useStore()
            const route = useRoute()
            const toast = inject('toast')
            const project_id = route.params.id
            const company = store.getters.getSelectedBoard
            const invitationData = computed(() => store.getters.getProjectInvitationData);
            const projectStatus = computed(() => store.getters.getProjectStatus);
            let setParams = {
                company_id: company.id,
                project_id: project_id,
            };

            let milestoneFixedText = reactive({
                text: getFormatedMileStone()
            });

            data.setURL = vueConfig.Project.Overview.MilestoneEndPoint
            onMounted( () => {
                getData();
            });

            /** watch **/
            permissionResolver('project-milestone', true);
            /** watch end **/

            async function getData() {
                await getAllData(data.setURL, setParams)
                    .then((res) => {
                        for (let i in data.dataList) {
                            data.dataList[i].is_edit = true;
                            if (data.dataList[i].date == null) {
                                data.dataList[i].type = 'text';
                            }
                            else {
                                data.dataList[i].type = 'date';
                            }
                        }
                    })
                let params = {
                    company_id: company.id,
                    status: 1,
                }

                // ON CHANGE GETTING DATA ONLY
                openModal(false, vueConfig.Setup.MilestoneEndPoint, params)
            }
            function addMore() {
                let object = {
                    id: "",
                    date: "",
                    company_id: company.id,
                    project_id: project_id,
                    is_edit: false,
                    milestone: null,
                    probable_date: "",
                    milestone_id: "",
                    type: 'text',
                };
                data.dataList.push(object)
            }
            async function deleteRow(rowIndex, row) {
                if (!row.id) {
                    data.dataList.splice(rowIndex, 1)
                } else {
                    //call axios
                    await deleteListData(`${data.setURL}/${row.id}`)
                    .then((res) => {
                        if (res) data.dataList.splice(rowIndex, 1)
                    })
                }
            }
            function checkIsItemDisabled(list) {
                for (let i in data.dataList) {
                    if (list.id == data.dataList[i].milestone_id) {
                        return true
                    }
                }
                return false;
            }

            function editRow(rowIndex) {
                data.dataList[rowIndex].is_edit = false
            }

            async function saveData(rowIndex) {
                if (!data.dataList[rowIndex].milestone_id && !data.dataList[rowIndex].data) {
                    toast.warning('Please select milestone and date first')
                    return
                }
                let formData = data.dataList[rowIndex];

                delete formData.type;

                await storeFormData({url: data.setURL}, formData, false, false, true)
                .then(() => {
                    getData();
                });
            }

            function makeItDateField(row) {
                row.type = 'date';
            }

            function makeItTextField(row) {
                row.type = 'text';
            }

            return {
                data, addMore, deleteRow, checkIsItemDisabled,
                editRow, saveData, invitationData, makeItDateField, makeItTextField, milestoneFixedText, projectStatus
            }
        }
    }
</script>

<style scoped>

    table tbody tr td span {
        cursor: pointer;
    }

    .remove-auto-overflow {
        overflow-x: unset;
    }

</style>
